<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      loading: true,
      isRouterAlive: true,
    };
  },
  created() {},
  methods: {
    reload() {
      let vue = this;
      vue.isRouterAlive = false;
      //在修改数据之后使用 $nextTick，则可以在回调中获取更新后的 DOM
      vue.$nextTick(() => {
        vue.isRouterAlive = true;
      });
    },
  },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
