<template>
  <el-container style="height: 100%">
    <el-aside width="auto">
      <v-sidebar />
    </el-aside>
    <el-container>
      <el-header style="background-color: #1890ff; color: #fff">
        <v-header />
      </el-header>
      <el-main>
        <div class="content-box" :class="{ 'content-collapse': collapse }">
          <v-tags></v-tags>
          <div class="content">
            <transition name="move" mode="out-in">
              <keep-alive :include="tagsList">
                <router-view></router-view>
              </keep-alive>
            </transition>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import vHeader from "./Header.vue";
import vSidebar from "./Sidebar.vue";
import vTags from "./Tags.vue";
import Event from "../../utils/Event";
import { mapState } from "vuex";
export default {
  // 组建的名称
  title: "",
  name: "Layout",
  computed: {
    ...mapState({
      // showLoader: state => state.vux.showLoader,
    }),
  },
  props: {},
  // 组建刷新
  inject: ["reload"],
  // 挂在组建
  components: { vHeader, vSidebar, vTags },
  data() {
    return {
      tagsList: [],
      collapse: false,
    };
  },
  // 初始化记载
  created() {
    let vue = this;
    Event.$on("collapse", (msg) => {
      this.collapse = msg;
    });

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    Event.$on("tags", (msg) => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
  },
  // DOM加载完毕执行操作
  mounted() {},
  // 事件处理
  methods: {},
  // 离开路由的操作
  destroyed() {},
};
</script>
<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
}
.el-main {
  background-color: #eee;
}
.el-breadcrumb {
  padding: 5px 20px;
}
.content-box {
  margin-top: 8px;
}
</style>
