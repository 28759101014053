<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">企业管理系统</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
	                <el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
	                    <router-link to="/Editor">
	                        <i class="el-icon-bell"></i>
	                    </router-link>
	                </el-tooltip>
	                <span class="btn-bell-badge" v-if="message"></span>
	            </div> -->
        <!-- 用户头像 -->
        <!-- <div class="user-avator">
	                <img src="../assets/img/img.jpg" />
	            </div> -->
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                ><div @click="menuClick('/changePwd')">
                  修改密码
                </div></el-dropdown-item
              >
              <el-dropdown-item divided command="loginout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Event from "../../utils/Event";
export default {
  // 组建的名称
  title: "",
  name: "Header",
  computed: {
    username(){
      return this.$store.getters.GET_TITLE;
    },
  },

  props: {},
  // 组建刷新
  inject: ["reload"],
  // 挂在组建
  components: {},
  data() {
    return {
      collapse: false,
      fullscreen: false,
      name: "admin",
      message: 2,
    };
  },
  // 初始化记载
  created() {},
  // DOM加载完毕执行操作
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
  // 事件处理
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        localStorage.removeItem("ms_username");
        this.$router.push("/login");
      }
    },
    // 侧边栏折叠
    collapseChage() {
      let vue = this;
      vue.collapse = !vue.collapse;
      Event.$emit("collapse", vue.collapse);
    },
    menuClick(index) {
      this.$router.push(index);
    },
  },
  // 离开路由的操作
  destroyed() {},
};
</script>
<style scoped>
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 60px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 60px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 60px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
</style>
