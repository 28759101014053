import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/layout/Layout.vue";
import Login from "../views/Login.vue";
import axios from "axios";
import store from "../store";
import { Message } from "element-ui";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Home,
    hidden: true,
    children: [
      {
        path: "/",
        name: "系统首页",
        meta: {
            title: '系统首页'
        },
        component: () => import ( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
      },
      
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/user",
    name: "员工管理",
    component: Home,
    children: [
      {
        path: "/yuangong",
        name: "员工列表",
        meta: {
          title: '员工列表'
        },
        component: () => import("../views/pages/yuangong/index.vue"),
      },    
      {
        path: "/bumens",
        name: "部门列表",
        meta: {
          title: '部门列表'
        },
        component: () => import("../views/pages/yuangong/bumens.vue"),
      }, 
      {
        path: "/piliang",
        name: "批量充值",
        meta: {
          title: '批量充值'
        },
        component: () => import("../views/pages/yuangong/piliang.vue"),
      },         
    ],
  },
  {
    path: "/cart",
    name: "购物车管理",
    component: Home,
    children: [
      {
        path: "/cartindex",
        name: "购物车列表",
        meta: {
          title: '购物车列表'
        },
        component: () => import("../views/pages/cart/index.vue"),
      },     
    ],
  },
  {
    path: "/liuishui",
    name: "流水管理",
    component: Home,
    children: [
      {
        path: "/liuishuiindex",
        name: "流水列表",
        meta: {
          title: '流水列表'
        },
        component: () => import("../views/pages/liushui/index.vue"),
      },     
    ],
  },
  {
    path: "/config",
    name: "系统管理",
    component: Home,
    children: [
      {
        path: "/company",
        name: "公司信息",
        meta: {
          title: '公司信息'
        },
        component: () => import("../views/pages/data/configs.vue"),
      },   
      {
        path: "/changePwd",
        name: "修改密码",
        meta: {
          title: '修改密码'
        },
        component: () => import("../views/pages/data/changPwd.vue"),
      }  
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path != "/login") {
    let data = {
      token: store.getters.GET_TOKEN,
    };
    next();
    axios
      .post("/admin/Login/checkToken", data)
      .then(function (res) {
        if (res.code != 200) {
          next({
            path: "/login",
          });
        } else {
          next();
        }
      })
      .catch(function (error) {
        Message.error({
          message: error,
        });
      });
  } else {
    next();
  }
 
});
export default router;
