<template>
  <div style="height: 100%; border: none; background-color: #fff">
    <div
      class="logo"
      style="height: 60px; background-color: #1890ff; color: #fff"
    ></div>
    <el-menu
      class="sidebar-el-menu"
      @select="menuClick"
      :default-active="onRoutes"
      :collapse="collapse"
      :unique-opened="opened"
      text-color="#333"
      active-text-color="#409EFF"
    >
      <template v-for="item in this.$router.options.routes">
        <template v-if="item.children && !item.hidden">
          <el-submenu :index="item.path" :key="item.path">
            <template slot="title">
              <i class="el-icon-s-shop"></i
              ><span slot="title">{{ item.name }}</span>
            </template>
            <!--子级路由-->
            <template v-for="subItem in item.children">
              <el-menu-item :index="subItem.path" :key="subItem.path">
                {{ subItem.name }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>

        <template v-else-if="!item.hidden">
          <el-menu-item :index="item.path" :key="item.path">
            <i class="el-icon-s-shop"></i
            ><span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Event from "../../utils/Event";

export default {
  // 组建的名称
  title: "",
  name: "Sidebar",
  data() {
    return {
      collapse: false,
    };
  },
  computed: {
    opened() {
      return this.$route.path == "/" ? false : true;
    },
    onRoutes() {
      return this.$route.path;
    },
  },
  props: {},
  // 组建刷新
  inject: ["reload"],
  // 初始化记载
  created() {
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    Event.$on("collapse", (msg) => {
      this.collapse = msg;
    });
  },
  // DOM加载完毕执行操作
  mounted() {},
  // 事件处理
  methods: {
    menuClick(index) {
      this.$router.push(index);
    },
  },
  // 离开路由的操作
  destroyed() {},
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.logo {
  height: 59px !important;
  line-height: 59px !important;
  box-shadow: none !important;
  transition: background 0.3s;
  padding-left: 24px;
}
</style>
