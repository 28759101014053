import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.sessionStorage.getItem("token"),
    title: window.sessionStorage.getItem("title"),
    tagsList:window.sessionStorage.getItem("tags")
  },
  getters: {
    GET_TOKEN: (state) => state.token,
    GET_TITLE: (state) => state.title,
    GET_TAGS:(state)=> state.tagsList,
  },
  mutations: {
    INIT_TOKEN(state, value) {
      state.token = value;
      window.sessionStorage.setItem("token", value);
    },
    INIT_TITLE(state, value) {
      state.title = value;
      window.sessionStorage.setItem("title", value);
    },
    INIT_TAGS(state,value){
      state.tagsList = JSON.stringify(value);
      window.sessionStorage.setItem("tags", JSON.stringify(value));
    }
  },
  actions: {},
  modules: {},
});
