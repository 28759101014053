<template>
  <div class="tags" v-if="showTags">
    <ul>
      <li
        class="tags-li"
        v-for="(item, index) in tagsList"
        :class="{ active: isActive(item.path) }"
        :key="index"
      >
        <router-link :to="item.path" class="tags-li-title">{{
          item.title
        }}</router-link>
        <span
          v-if="item.path != '/'"
          class="tags-li-icon"
          @click="closeTags(index)"
        >
          <i class="el-icon-close"></i>
        </span>
      </li>
    </ul>

    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button size="mini" type="primary" style="min-height: 44px">
          标签选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu size="small">
            <el-dropdown-item command="other">关闭其他</el-dropdown-item>
            <el-dropdown-item command="all">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  // 组建的名称
  title: "",
  name: "Tags",
  computed: {
    showTags() {
      return this.tagsList.length > 0;
    },
  },

  props: {},
  // 组建刷新
  inject: ["reload"],
  // 挂在组建
  components: {},
  data() {
    return {
      tagsList: [
        {
          path: "/",
          title: "系统首页",
        },
      ],
      tabindex: 0,
    };
  },
  // 初始化记载
  created() {
    let vue = this;
    vue.setTags(vue.$route);
    vue.$on("close_current_tags", () => {
      for (let i = 0, len = this.tagsList.length; i < len; i++) {
        const item = this.tagsList[i];
        if (item.path === this.$route.fullPath) {
          if (i < len - 1) {
            this.$router.push(this.tagsList[i + 1].path);
          } else if (i > 0) {
            this.$router.push(this.tagsList[i - 1].path);
          } else {
            this.$router.push("/");
          }
          this.tagsList.splice(i, 1);
        }
      }
    });
  },
  // DOM加载完毕执行操作
  mounted() {},
  // 事件处理
  methods: {
    isActive(path) {
      return path === this.$route.fullPath;
    },
    // 关闭单个标签
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.fullPath && this.$router.push(item.path);
      } else {
        this.$router.push("/");
      }
      window.sessionStorage.setItem("tags", JSON.stringify(this.tagsList));
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [
        {
          path: "/",
          title: "系统首页",
        },
      ];
      window.sessionStorage.setItem("tags", JSON.stringify(this.tagsList));
      this.$router.push("/");
    },
    // 关闭其他标签
    closeOther() {
      if (this.$route.fullPath == "/") {
        return false;
      }
      const curItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath;
      });

      this.tagsList = [
        {
          path: "/",
          title: "系统首页",
        },
      ];
      this.tagsList.push(curItem[0]);
      this.$route.fullPath = curItem[0].path;
      window.sessionStorage.setItem("tags", JSON.stringify(this.tagsList));
    },
    // 设置标签
    setTags(route) {
      let vue = this;
      let tagsList = JSON.parse(window.sessionStorage.getItem("tags") || "[]");

      if (tagsList.length == 0) {
        tagsList.push({
          title: "系统首页",
          path: "/",
        });
      }
      const isExist = tagsList.some((item) => {
        return item.path === route.fullPath;
      });

      if (!isExist) {
        tagsList.push({
          title: route.meta.title,
          path: route.fullPath,
        });
      }

      vue.tagsList = tagsList;

      window.sessionStorage.setItem("tags", JSON.stringify(tagsList));
      vue.$emit("tags", vue.tagsList);
    },
    handleTags(command) {
      command === "other" ? this.closeOther() : this.closeAll();
    },
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
    },
  },
  // 离开路由的操作
  destroyed() {},
};
</script>

<style>
.tags {
  position: sticky;
  height: 46px;
  overflow: hidden;
  background: #fff;
  padding-right: 120px;
  box-shadow: 0 5px 10px #ddd;
  top: -20px;
  z-index: 999;
  padding-left: 20px;
  margin-top: -19px;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  position: relative;
  float: left;
  margin: 3px 5px 2px 3px;
  font-size: 14px;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #fff;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #333;
}

.tags-li-title {
  float: left;
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #409eff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 44px;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.tags-li-icon {
  position: absolute;
  right: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  cursor: pointer;
}
.el-icon-close {
  padding: 3px;
  width: 12px;
  font-size: 12px;
  height: 12px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 100%;
}
.el-icon-close:hover {
  color: #fff;
  background-color: #409eff;
}
.tags-li:hover .tags-li-icon {
  opacity: 1;
}
</style>
